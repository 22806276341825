import {
  Box, ChakraProvider, Grid, Heading, theme
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <Heading color='gray.500' fontSize='2xl'>Hi! I am<Heading color='blue.700'> Bobby Carelse.</Heading></Heading>
      </Grid>
    </Box>
  </ChakraProvider>
)
